<template>
  <Transition name="banner-sticky" appear>
    <div
      v-if="shouldShowBanner"
      class="sticky w-full bottom-0 right-0 z-[9999]"
    >
      <div class="layout-banner bg-white shadow-lg py-40 relative">
        <Container>
          <div class="flex flex-col md:flex-row gap-20 lg:gap-40">
            <div
              v-if="cookieBannerText"
              class="content"
              v-html="cookieBannerText"
            />
            <div class="flex flex-col gap-20 min-w-[300px]">
              <ButtonStyle v-slot="{ classes }" green>
                <button
                  :class="classes"
                  class="justify-center accept-cookies-handler"
                  @click="setCookieValue(CookieConsentValue.AcceptAll)"
                >
                  {{
                    $texts('cookieBanner.accepted', 'Weiter mit allen Cookies')
                  }}
                </button>
              </ButtonStyle>
              <ButtonStyle v-slot="{ classes }" green outlined>
                <button
                  :class="classes"
                  class="justify-center"
                  @click="setCookieValue(CookieConsentValue.AcceptPartial)"
                >
                  {{
                    $texts(
                      'cookieBanner.partiallyAccepted',
                      'Weiter mit erforderlichen Cookies',
                    )
                  }}
                </button>
              </ButtonStyle>
            </div>
            <button
              class="absolute top-0 right-0 p-15"
              @click="setCookieValue(CookieConsentValue.None)"
            >
              <SpriteSymbol
                name="close"
                class="w-20 h-20 md:w-30 md:h-30 fill-current text-green hover:text-green-alt"
              />
            </button>
          </div>
        </Container>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { useGlobalConfig } from '~/stores/globalConfig'

enum CookieConsentValue {
  /**
   * No cookies are accepted.
   */
  None = '0',

  /**
   * All cookies are accepted.
   */
  AcceptAll = '1',

  /**
   * Only functional/required cookies accepted.
   */
  AcceptPartial = '2',
}

const globalConfig = useGlobalConfig()

// For the pharmasuise site the domain is set to be .pharmasuisse.org so that
// it can be shared with info.pharmasuisse.org.
const cookieDomain = useRuntimeConfig().public.cookieDomain
const cookie = useCookie<CookieConsentValue | undefined>('phs_cookie', {
  domain: cookieDomain,
  // Explicitly check the value of the cookie to be valid. The default decoder
  // will convert the string to numbers.
  decode: (value) => {
    if (
      value === CookieConsentValue.None ||
      value === CookieConsentValue.AcceptPartial ||
      value === CookieConsentValue.AcceptAll
    ) {
      return value
    }
    return undefined
  },
})

const shouldShowBanner = computed(() => cookie.value === undefined)
const cookieBannerText = computed(() => globalConfig.cookieBannerText || '')

const setCookieValue = (v: CookieConsentValue) => (cookie.value = v)
</script>

<style lang="postcss">
.layout-banner {
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.13);
}

/* Banner visible */
.banner-sticky-enter-active,
.banner-sticky-leave-active {
  /* During a transform transition, the element still takes up it's defined height, this causes a jump in the page height when the element is finally unmounted. To prevent this, we animate the max-height property. */
  @apply transition-[max-height] duration-300 max-h-[400px];
}

/* Banner hidden */
.banner-sticky-enter-from,
.banner-sticky-leave-to {
  @apply max-h-0 overflow-hidden;
}
</style>
